const createTexture = (size, resolution, color) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const radius = size / 2;
  canvas.width = canvas.height = size * resolution;
  const gradient = context.createRadialGradient(
    radius,
    radius,
    0,
    radius,
    radius,
    radius
  );
  gradient.addColorStop(0, color);
  gradient.addColorStop(0.3, "rgba(155,81,224, 0.1)");
  gradient.addColorStop(1, "rgba(0,0,0,0)");
  context.fillStyle = gradient;
  context.fillRect(0, 0, size, size);
  return canvas;
};

const getNumberOfParticles = (width, height) => {
  const s = width * height;

  if (width <= 768) {
    return (s / 10000) | 0;
  } else {
    return (s / 25000) | 0;
  }
};

export const particles = (canvasWidth, canvasHeight, ctx) => {
  const numberOfParticles = getNumberOfParticles(canvasWidth, canvasHeight);
  // console.log(numberOfParticles);
  const particlesArray = [];
  const resolution = window.devicePixelRatio || 1;
  const size = 20;
  const radius = size / 2;
  const colors = [
    "rgba(155,81,224, 1)",
    "rgba(79,84,255,1)",
    "rgba(255,79,235,0.91)",
  ];
  const textures = colors.map((color) =>
    createTexture(size, resolution, color)
  );

  class Points {
    constructor(x, y, directionX, directionY, colorIndex) {
      this.x = x;
      this.y = y;
      this.directionX = directionX;
      this.directionY = directionY;
      this.colorIndex = colorIndex;
    }

    draw() {
      ctx.drawImage(
        textures[this.colorIndex],
        this.x - radius,
        this.y - radius
      );
    }

    // check particle position, check moouse position, move the particles draw the particles

    update() {
      // Check if particle is still within canvas
      if (this.x > canvasWidth || this.x < 0) {
        this.directionX = -this.directionX;
      }

      if (this.y > canvasHeight || this.y < 0) {
        this.directionY = -this.directionY;
      }

      // move non colliding particles adding direction to position
      this.x += this.directionX;
      this.y += this.directionY;
      this.draw();
    }
  }

  const connect = () => {
    let opacityValue = 1;
    for (let a = 0; a < particlesArray.length; a++) {
      for (let b = a; b < particlesArray.length; b++) {
        let distance =
          ((particlesArray[a].x - particlesArray[b].x) *
            (particlesArray[a].x - particlesArray[b].x) +
            (particlesArray[a].y - particlesArray[b].y) *
              (particlesArray[a].y - particlesArray[b].y)) |
          0;

        if (distance < (canvasWidth / 10) * (canvasHeight / 10)) {
          opacityValue = 1 - distance / 17000;
          ctx.strokeStyle = `rgba(112, 178, 252,${opacityValue})`;
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
          ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
          ctx.stroke();
        }
      }
    }
  };

  const animate = () => {
    requestAnimationFrame(animate);
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    for (let i = 0; i < particlesArray.length; i++) {
      particlesArray[i].update();
    }
    connect();
  };

  const init = () => {
    for (let i = 0, len = numberOfParticles; i < len; i++) {
      let x = (Math.random() * canvasWidth) | 0;
      let y = (Math.random() * canvasHeight) | 0;
      let directionX = Math.random() * 5 - 2.5; // -2.5 to 2.5
      let directionY = Math.random() * 5 - 2.5; // -2.5 to 2.5
      let colorIndex = (Math.random() * 3) | 0;
      particlesArray.push(new Points(x, y, directionX, directionY, colorIndex));
    }
  };

  const run = () => {
    init();
    animate();
  };

  return { run };
};
