import React from "react";
import { v4 as uuidv4 } from "uuid";
import { graphql, StaticQuery } from "gatsby";

import { Autoplay, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import { respH } from "../../lib/helpers";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "./banner-section.styles.scss";

const BannerSection = () => {
  return (
    <StaticQuery
      query={graphql`
        query Titles {
          slides: allSanityBanner {
            nodes {
              titles
            }
          }
        }
      `}
      render={(data) => (
        <section className="section banner is-medium">
          <div className="container">
            <div className="swiper">
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{
                  dynamicBullets: true,
                }}
                autoplay
              >
                {data.slides &&
                  data.slides.nodes[0].titles &&
                  data.slides.nodes[0].titles.map((title) => (
                    <SwiperSlide key={uuidv4()}>
                      <h2 className={`title ${respH(2)} has-text-centered`}>
                        {title}
                      </h2>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </section>
      )}
    />
  );
};

export default BannerSection;
