import React, { useEffect } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  FaAward,
  FaBuilding,
  // FaCalendarDay,
  FaGraduationCap,
  FaBullhorn,
} from "react-icons/fa";

import BannerSection from "../components/banner-section/banner-section.component";
import CanvasSection from "../components/canvas-section/canvas-section.component";
import LatestNews from "../components/latest-news/latest-news.component";
import Layout from "../components/layout";
import NewsLetter from "../components/news-letter/news-letter.component";
import Seo from "../components/seo";
import animations from "../lib/animations.lib";
import { respH } from "../lib/helpers";

import "./index.styles.scss";

// markup
const IndexPage = () => {
  useEffect(() => {
    const animate = animations();
    animate.animateFadeIn();
  });

  return (
    <Layout pageClass={`home`}>
      <Seo title="Home" />
      <CanvasSection />
      <BannerSection />

      <section className="section services person is-medium">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)}`}>
                <FaAward /> Corsi per professionisti
              </h1>
            </div>
          </div>
          <div className="columns fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-one"
                quality="85"
                alt="corsi per professionisti image"
                src="../images/home/0.png"
              />
            </div>
            <div className="column has-text-centered is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(3)} has-text-weight-light`}>
                  Corsi dedicati alle persone per lo sviluppo professionale
                </h2>
                <Link
                  to="/servizi#corsi-professionisti"
                  className="button is-medium is-rounded "
                >
                  Approfondisci
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section services business is-medium">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)}`}>
                <FaBuilding /> Corsi per aziende
              </h1>
            </div>
          </div>
          <div className="columns fade-in has-reversed-columns">
            <div className="column has-text-centered is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(3)} has-text-weight-light`}>
                  La formazione è uno degli strumenti più potenti per fornirsi
                  di nuove competenze e per migliorare la produttività aziendale
                </h2>
                <Link
                  to="/servizi#corsi-aziende"
                  className="button is-medium is-rounded "
                >
                  Approfondisci
                </Link>
              </div>
            </div>
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-two"
                quality="85"
                alt="corsi per aziende image"
                src="../images/home/1.png"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section categories is-medium">
        <div className="container">
          <div className="columns mb-6">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)}`}>
                <FaGraduationCap /> Building Information Modeling (BIM)
              </h1>
            </div>
          </div>
          <div className="columns fade-in">
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-three "
                alt="Bim image"
                src="../images/home/4.jpg"
              />
            </div>
            <div className="column has-text-centered is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(3)} has-text-weight-light`}>
                  In ambito BIM proponiamo sia corsi su software Autodesk:
                  Revit, Dynamo, Navisworks; sia corsi volti all’ottenimento
                  delle certificazioni professionali di Bim Specialist, Bim
                  Coordinator, Bim Manager.
                </h2>
                <Link to="/corsi" className="button is-medium is-rounded ">
                  I nostri corsi
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section categories is-medium">
        <div className="container">
          <div className="columns mb-6">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)}`}>
                <FaGraduationCap /> Project Management
              </h1>
            </div>
          </div>
          <div className="columns fade-in has-reversed-columns">
            <div className="column has-text-centered is-vcenter-desktop">
              <div>
                <h2 className={`title ${respH(3)} has-text-weight-light`}>
                  In ambito Project Mangement proponiamo corsi per le
                  certificazioni ISPM base e avanzata e per la certificazione
                  delle conoscenze e competenze del Project Manager come
                  indicato nella norma UNI ISO 21500.
                </h2>
                <Link
                  to="/corsi-eventi"
                  className="button is-medium is-rounded "
                >
                  I nostri corsi
                </Link>
              </div>
            </div>
            <div className="column has-padding-mobile">
              <StaticImage
                className="clipped clipped-four"
                alt="Project Management image"
                src="../images/home/3.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <section className='section courses'> */}
      {/*   <div className='container'> */}
      {/*     <div className='columns'> */}
      {/*       <div className='column has-text-centered'> */}
      {/*         <h1 className={ `title ${respH(1)}` }><FaCalendarDay /> Ultimi arrivi</h1> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </section> */}

      <section className="section latest-news">
        <div className="container">
          <div className="columns mb-6">
            <div className="column has-text-centered">
              <h1 className={`title ${respH(1)}`}>
                <FaBullhorn /> News
              </h1>
            </div>
          </div>
          <LatestNews />
        </div>
      </section>
      <NewsLetter />
    </Layout>
  );
};

export default IndexPage;
