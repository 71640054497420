import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Autoplay, Pagination } from "swiper";
import PostPreview from "../post-preview/post-preview.component";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

const LatestNews = () => {
  const postPreviewOpts = {
    author: false,
    shadows: false,
  };

  return (
    <StaticQuery
      query={graphql`
        query LatestPosts {
          posts: allSanityPost(
            limit: 6
            sort: { fields: [publishedAt], order: DESC }
            filter: {
              slug: { current: { ne: null } }
              publishedAt: { ne: null }
            }
          ) {
            edges {
              node {
                id
                publishedAt
                title
                summary
                slug {
                  current
                }
                categories {
                  title
                }
                mainImage {
                  asset {
                    url
                    gatsbyImageData(
                      width: 220
                      height: 160
                      placeholder: BLURRED
                    )
                  }
                }
                author {
                  name
                  image {
                    asset {
                      gatsbyImageData(
                        width: 48
                        aspectRatio: 1
                        placeholder: BLURRED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="columns is-centered">
          <div className="column is-full">
            <div className="swiper">
              <Swiper
                slidesPerView={1}
                pagination={{
                  dynamicBullets: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                }}
                autoplay
                modules={[Pagination, Autoplay]}
              >
                {data.posts &&
                  mapEdgesToNodes(data.posts)
                    .filter(filterOutDocsWithoutSlugs)
                    .filter(filterOutDocsPublishedInTheFuture)
                    .map((node) => (
                      <SwiperSlide key={node.id}>
                        <PostPreview node={node} options={postPreviewOpts} />
                      </SwiperSlide>
                    ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default LatestNews;
