import React, { useRef, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { particles } from "../../lib/canvas.lib";
import { respH } from "../../lib/helpers";
// import heroImage from '../../images/home/0.png';

const Canvas = () => {
  const { site } = useStaticQuery(query);
  const canvasRef = useRef(null);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const updateSize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight * 0.65);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    updateSize();
    const system = particles(width, height, ctx);
    system.run();
  });

  useEffect(() => {
    // console.log('ADD resize event listener');
    window.addEventListener("resize", updateSize);
    return () => {
      // console.log('REMOVE resize event listener');
      window.removeEventListener("resize", updateSize);
    };
  });

  return (
    <section id="opening">
      <canvas
        ref={canvasRef}
        style={{
          // background: `linear-gradient( 109.6deg, rgba(156,252,248,0.1), transparent 40%, transparent 60%, rgba(110,123,251,0.1)), radial-gradient( ellipse closest-side, transparent, transparent 24%, rgba(110,123,251,0.1)), url(${heroImage})`
          background: `linear-gradient( 109.6deg, rgba(156,252,248,0.1), transparent 40%, transparent 60%, rgba(110,123,251,0.1)), radial-gradient( ellipse closest-side, transparent, transparent 24%, rgba(110,123,251,0.1))`,
        }}
        width={width}
        height={height}
      />
      <div className="lead has-text-centered ">
        <h1 className={`title ${respH(1)}`}>
          {site.siteMetadata?.title || "La Fucina dei Talenti"}
        </h1>
        <h2 className={`subtitle ${respH(2)} has-text-weight-light`}>
          Never stop learning
        </h2>
      </div>
    </section>
  );
};

export default Canvas;

const query = graphql`
  query CanvasOptions {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
